.mitch-d3-tree {
	&.boxed-tree {
		&.default {
			.link {
				fill: none;
				stroke: #ccc;
				stroke-width: 2px;
			}
	
			.node {
				&.collapsed .body-group .body-box {
					fill: lightsteelblue;	
				}
	
				&.selected .body-group .body-box {
					stroke: #00A2A3;
					stroke-width: 2.5px;
				}
	
				.body-group .body-box {
					stroke: steelblue;
					fill: white;
					rx: 6;
					ry: 6;
				}
				
				.title-group .title-box {
					stroke: steelblue;
					fill: #4682B4;
					rx: 10;
					ry: 10;
				}
				
				/*
					Do NOT do any font related CSS that
					would affect dimensions here, as 
					D3Plus TextBox won't take them 
					into account when performing
					various operations such as ellipsis.
				*/
				.title-group text {
					fill: white;
				}
			}
		}
	}
}